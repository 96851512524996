import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import {
  Contact,
  Header,
  Exhibitions,
  Gallery,
  Layout,
  Statement,
} from "../components";

const IndexPage = ({ data }: any) => {
  console.log(data);
  return (
    <Layout>
      <Header />
      <Statement />
      <Exhibitions />
      <Gallery data={data} />
      <Contact />
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Nicole Minton Artist</title>;

export const query = graphql`
  query {
    allDataInJsonJson {
      edges {
        node {
          sold
          name
          price
          description
          available
          dimensionsInCm {
            depth
            height
            width
          }
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 500
              )
            }
          }
        }
      }
    }
  }
`;
